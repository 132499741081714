var myModal = new bootstrap.Modal(document.getElementById('exampleModal'), {
    keyboard: false
})
Dropzone.autoDiscover = false;
jQuery(document).ready(function ($) {

    function File_valid(file) {
        this.themeZipFileStatus = '';
        this.themeZipFileupload = function () {
            if (file != '') {
                this.themeZipFileStatus = file;
            }
        }
    }


    function File_valid2(file) {
        this.previewImageStatus = false;
        this.previewImageUpload = function (themeZipFileStatus) {
            if (file != '') {
                this.previewImageStatus = true;
                this.themeZipFileStatus = themeZipFileStatus;
            }
        }
    }

    $('.loadmore').click(function () {


        currentPage = Number($('.urlInfinite').attr("data-current-page"));
        nextPage = currentPage + 1;
        ajaxparams = { action: "loadMore", currentPage: currentPage };

        if($('body').hasClass('search')){
            searchkey = $('.urlInfinite').attr("data-attr-searchkey");

            searchtype = $('.urlInfinite').attr("data-attr-searchtype");

            if(searchkey != ''){
                ajaxparams.searchkey = searchkey;
            }

            if(searchtype != ''){
                ajaxparams.searchtype = searchtype;
            }
        } else {
            ajaxparams.categoryId = Number($('.urlInfinite').val());;
        }
        //if(catId != 0){
        //}


        $.ajax({
            type: "post",
            //dataType : "json",
            //url : myAjax.ajaxurl,
            url: myAjax.ajaxurl,
            //data: {action: "infinite_scroll", nonce: nonce, currentPage: currentPage, categoryId: catId},
            data: ajaxparams,
            beforeSend: function () {
                $('.ajaxLoading').html('<img src="https://wpatlas.com/wp-content/themes/wp-atlas/assets/images/loader.gif" style="width:45px;"/>');
            },
            success: function (response) {
                if (response) {
                    $(".jscroll1").append(response);
                    $(".urlInfinite").attr("data-current-page", nextPage);
                }
                else {
                    $('.loadMoreButton').hide();
                }
            },
            complete: function () {
                $('.ajaxLoading').html('');
            }
        });
    });


    /*function infinite_scroll() {
        //e.preventDefault();
        nonce = $('.urlInfinite').attr("data-nonce");
        currentPage = Number($('.urlInfinite').attr("data-current-page"));
        searchkey = $('.urlInfinite').attr("data-attr-searchkey");

        searchtype = $('.urlInfinite').attr("data-attr-searchtype");
        nextPage = currentPage + 1;
        ajaxparams = { action: "infinite_scroll", nonce: nonce, currentPage: currentPage };
        if(catId != 0){
            ajaxparams.categoryId = catId;
        }
        if(searchkey != ''){
            ajaxparams.searchkey = searchkey;
        }

        if(searchtype != ''){
            ajaxparams.searchtype = searchtype;
        }


        //console.log(ajaxparams);
        $.ajax({
            type: "post",
            //dataType : "json",
            //url : myAjax.ajaxurl,
            url: myAjax.ajaxurl,
            //data: {action: "infinite_scroll", nonce: nonce, currentPage: currentPage, categoryId: catId},
            data: ajaxparams,
            beforeSend: function () {
                $('.ajaxLoading').html('<img src="http://localhost/wp-atlas/wp-content/themes/wp-atlas/assets/images/loader.gif" style="width:45px;"/>');
            },
            success: function (response) {
                if (response) {
                    $(".jscroll").append(response);
                    $(".urlInfinite").attr("data-current-page", nextPage);
                }
                else {
                    alert("Your like could not be added");
                }
            },
            complete: function () {
                $('.ajaxLoading').html('');
            }
        });
    };*/

    /*let fileStatus = new File_valid('test');
     fileStatus.themeZipFileupload();

     console.log(fileStatus);


     let previewStatus = new File_valid2('image1');
     previewStatus.previewImageUpload(fileStatus.themeZipFileStatus);

     console.log(previewStatus.previewImageStatus);*/


    /*function file_validation(file){*/
    /*themeFileDropzone.files[0].status = '';
     previewImageDropzone.files[0].status = '';
     thumbnailImageDropzone.files[0].status = '';*/



    $('.download_file').click(function (e) {
        e.preventDefault();
        keyVal = $(this).attr('data-attr-key');
        dataId= $(this).attr('data-attr-id');
        $.ajax({
            type: "post",
            //dataType : "json",
            //url : myAjax.ajaxurl,
            url: myAjax.ajaxurl,
            data: {action: 'wpatlasFilehandler', key: keyVal, dataId: dataId},
            beforeSend: function () {
                $('.download_file').html('<i class="fas fa-circle-notch fa-spin"></i>');
            },
            success: function (response) {
                if (response) {
                    window.location.replace(response);
                }
                else {
                    alert("Your like could not be added");
                }
            },
            complete: function () {
                $('.download_file').html('Download');
            }
        });
    });



    $(".loginModal").click(function(){
        //alert('test');
        //jQuery("#exampleModal").modal({backdrop: "static"});
        myModal.show();
    });






    if($('body').hasClass('page-template-t-upload-theme')) {

        upload_files_submit_theme = $('.wp_atlas_UploadNonce').attr('data-nonce');

        /*$('#myDropzone1').dropzone({*/
        var thumbnailImageDropzone = new Dropzone("#myDropzone1", {
            maxFiles: 2000,
            uploadMultiple: false,
            parallelUploads: 5,
            maxFiles: 1,
            acceptedFiles: 'image/*',
            addRemoveLinks: true,
            //url: "http://localhost/wp-atlas/wp-content/themes/wp-atlas/template-parts/submit-upload.php",
            url: myAjax.ajaxurl + '?action=upload_files_submit_theme&upload_files_submit_theme=' + upload_files_submit_theme,
            //data : {action: "upload_files_submit_theme"},
            success: function (file, response) {
                //console.log(response);
                //document.getElementById("submit-all").disabled = false;
                checkAllFilesUpload();
            },
            removedfile: function (file) {
                var name = file.name;
                $.ajax({
                    type: 'POST',
                    url: myAjax.ajaxurl,
                    data: {
                        action: "remove_upload_files_submit_theme",
                        upload_files_submit_theme: upload_files_submit_theme,
                        filename: name
                    },
                });
                var _ref;
                return (_ref = file.previewElement) != null ? _ref.parentNode.removeChild(file.previewElement) : void 0;
            }
        });

        /*$('#myDropzone2').dropzone({*/
        var previewImageDropzone = new Dropzone("#myDropzone2", {
            maxFiles: 2000,
            uploadMultiple: false,
            parallelUploads: 5,
            maxFiles: 1,
            acceptedFiles: 'image/*',
            addRemoveLinks: true,
            //url: "http://localhost/wp-atlas/wp-content/themes/wp-atlas/template-parts/submit-upload.php",
            url: myAjax.ajaxurl + '?action=upload_files_submit_theme&upload_files_submit_theme=' + upload_files_submit_theme,
            //data : {action: "upload_files_submit_theme"},
            success: function (file, response) {
                //console.log(response);
                //document.getElementById("submit-all").disabled = false;
                checkAllFilesUpload();
            },
            removedfile: function (file) {
                var name = file.name;
                $.ajax({
                    type: 'POST',
                    url: myAjax.ajaxurl,
                    data: {
                        action: "remove_upload_files_submit_theme",
                        upload_files_submit_theme: upload_files_submit_theme,
                        filename: name
                    },
                });
                var _ref;
                return (_ref = file.previewElement) != null ? _ref.parentNode.removeChild(file.previewElement) : void 0;
            }
        });

        //$('#myDropzone').dropzone({
        var themeFileDropzone = new Dropzone("#myDropzone", {
            maxFiles: 2000,
            uploadMultiple: false,
            parallelUploads: 5,
            maxFiles: 1,
            acceptedFiles: '.zip',
            addRemoveLinks: true,
            //previewTemplate: document.getElementById('template-preview').innerHTML,
            //url: "http://localhost/wp-atlas/wp-content/themes/wp-atlas/template-parts/submit-upload.php",
            url: myAjax.ajaxurl + '?action=upload_files_submit_theme&upload_files_submit_theme=' + upload_files_submit_theme,
            //data : {action: "upload_files_submit_theme"},
            /*init: function() {
             this.on("maxfilesexceeded", function (file) {
             alert("No more files please!");
             });
             },*/
            success: function (file, response) {
                checkAllFilesUpload();
            },
            removedfile: function (file) {
                var name = file.name;
                $.ajax({
                    type: 'POST',
                    url: myAjax.ajaxurl,
                    data: {
                        action: "remove_upload_files_submit_theme",
                        upload_files_submit_theme: upload_files_submit_theme,
                        filename: name
                    },
                    success: function (file, response) {
                        console.log(response);

                        //document.getElementById("submit-all").disabled = true;

                    }
                });
                var _ref;
                return (_ref = file.previewElement) != null ? _ref.parentNode.removeChild(file.previewElement) : void 0;
            }
        });




        function checkAllFilesUpload() {
            if (themeFileDropzone.files.length > 0 && previewImageDropzone.files.length > 0 && thumbnailImageDropzone.files.length > 0) {
                document.getElementById("submit-all").disabled = false;
            }
            else {
                document.getElementById("submit-all").disabled = true;
            }
        }

        window.addEventListener('load', function () {
            var forms = document.getElementsByClassName('needs-validation');
            // Loop over them and prevent submission
            var validation = Array.prototype.filter.call(forms, function (form) {
                form.addEventListener('submit', function (event) {

                    console.log(event);

                    if (form.checkValidity() === false) {
                        event.preventDefault();
                        event.stopPropagation();
                        form.classList.remove('was-validated');
                    }
                    else if (form.checkValidity() === true) {

                        event.preventDefault();

                        /*if(themeFileDropzone.files[0].status == 'success' && previewImageDropzone.files[0].status == 'success' && thumbnailImageDropzone.files[0].status == 'success'){

                         }
                         else{

                         }*/


                        console.log(themeFileDropzone);

                        console.log(themeFileDropzone.files[0].name);
                        console.log(themeFileDropzone.files[0].status);
                        console.log(previewImageDropzone.files[0].name);
                        console.log(previewImageDropzone.files[0].status);
                        console.log(thumbnailImageDropzone.files[0].name);
                        console.log(thumbnailImageDropzone.files[0].status);


                        //$('.uploadContent').click(function (e) {
                        //e.preventDefault();
                        console.log('hello');

                        /*let fileStatus = new File_valid(myDropzone.files[0].name);
                         fileStatus.themeZipFileupload();

                         console.log(fileStatus);*/


                        /*let previewStatus = new File_valid2('image1');
                         previewStatus.previewImageUpload(fileStatus.themeZipFileStatus);

                         console.log(previewStatus.previewImageStatus);*/

                        themeZipFile = themeFileDropzone.files[0].name;

                        previewImage = previewImageDropzone.files[0].name;
                        thumbnailImage = thumbnailImageDropzone.files[0].name;

                        //console.log(name);
                        upload_files_submit_theme = $('.wp_atlas_UploadNonce').attr('data-nonce');
                        title = $('#Title').val();
                        description = $('#Description').val();
                        demoUrl = $('#DemoURL').val();
                        contentCategory = $('#ContentCategory').val();
                        gutembergOptimized = $('#gutembergOptimized').val();
                        ChildTheme = $('#ChildTheme').val();
                        Documentation = $('#Documentation').val();
                        HighResolution = $('#HighResolution').val();
                        compatibleBrowsers = $('#CompatibleBrowsers').val();
                        tags = $('#Tags').val();
                        $.ajax({
                            type: "post",
                            //dataType : "json",
                            //url : myAjax.ajaxurl,
                            url: myAjax.ajaxurl,
                            data: {
                                action: "wpatlas_save_user_submitted_theme_into_post",
                                upload_files_submit_theme: upload_files_submit_theme,
                                title: title,
                                description: description,
                                demoUrl: demoUrl,
                                contentCategory: contentCategory,
                                gutembergOptimized: gutembergOptimized,
                                ChildTheme: ChildTheme,
                                Documentation: Documentation,
                                HighResolution: HighResolution,
                                compatibleBrowsers: compatibleBrowsers,
                                tags: tags,
                                themeZipFile: themeZipFile,
                                previewImage: previewImage,
                                thumbnailImage: thumbnailImage,

                            },
                            success: function (response) {
                                if (response) {
                                    //$(".jscroll").append(response);
                                    console.log(response);
                                    if (response == 1) {
                                        Dropzone.forElement("#myDropzone").removeAllFiles(true);
                                        Dropzone.forElement("#myDropzone1").removeAllFiles(true);
                                        Dropzone.forElement("#myDropzone2").removeAllFiles(true);
                                        document.getElementById("UploadFormId").reset();
                                        form.classList.remove('was-validated');
                                        document.getElementById("submit-all").disabled = true;
                                    }

                                }
                                else {
                                    alert("Error submitting form.");
                                }
                            }
                        });
                        //});
                    }
                    form.classList.add('was-validated');
                }, false);
            });
            /*}*/
        });


        document.getElementById("submit-all").disabled = true;
    }

});
/*

var myDropzone = new Dropzone(document.body, {
    url: "",
    thumbnailWidth: 80,
    thumbnailHeight: 80,
    parallelUploads: 20,
    maxFilesize: 2, // membatasi ukuran file yang di upload
    acceptedFiles: "image/jpg, image/jpeg",
    previewTemplate: previewTemplate,
    autoQueue: false, //manual,
    previewsContainer: "#previews", //preview,
    clickable: ".fileinput-button"

});*/


